import React, { useState } from 'react';

import {
  QAlert,
  QBox,
  QButton,
  QCloseButton,
  deprecated,
  QDrawer,
  QEmptyState,
  QMenuButton,
  QMenuItem,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpinner,
  QStack,
  QText,
} from '@qualio/ui-components';
import { useFormik } from 'formik';
import { useAuditDeleteMutate, useAuditMutation, useAuditsQuery, useConfigsQuery, usePoliciesQuery } from 'hooks';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Audit } from 'types';
import { ViewFileCard, CreateAuditForm, NextAudit } from 'v2components';
import { ConfigItem } from 'v2types';
import { useRouterOutletSupplier } from 'v2views';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { getAuditFormObject } from './SupplierAudits.const';

const COLUMNS: deprecated.QDataTableProps['columns'] = [
  { Header: 'Name', accessor: 'name', width: '30%' },
  { Header: 'Type', accessor: 'auditType' },
  { Header: 'Notes', accessor: 'notes' },
  { Header: 'Added by', accessor: 'addedBy' },
  { Header: 'Date completed', accessor: 'dateCompleted' },
  { Header: 'Date added', accessor: 'dateAdded' },
  { Header: 'Documents', accessor: 'auditDocuments' },
  { Header: '', accessor: 'menu', width: '5%' },
];

const SupplierAudits: React.FC = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [selectedAudit, setSelectedAudit] = useState<Audit | null>(null);
  const [showAddAuditModal, setShowAddAuditModal] = useState(false);
  const { supplier } = useRouterOutletSupplier();
  const { isConfigsLoading, auditTypeOptions } = useConfigsQuery();
  const { policyLinks } = usePoliciesQuery(isConfigsLoading);
  const [deleteAuditModalState, setDeleteAuditModalState] = useState<Audit | undefined>(undefined);
  const [addAuditSubmitButtonDisabled, setAddAuditSubmitButtonDisabled] = useState<boolean>(false);

  const { audits, isAuditsLoading, refetchAudits } = useAuditsQuery(supplierId!);

  const onSuccess = () => {
    setShowAddAuditModal(false);
    formik.resetForm();
    refetchAudits();
  };

  const onDeleteSuccess = () => {
    setDeleteAuditModalState(undefined);
    refetchAudits();
  };

  const { deleteAudit, isAuditDeleteLoading } = useAuditDeleteMutate(onDeleteSuccess);
  const { createAudit, isAuditLoading } = useAuditMutation(onSuccess);
  const formik = useFormik<any>({
    initialValues: getAuditFormObject('defaultValue'),
    validationSchema: toFormikValidationSchema(z.object(getAuditFormObject('validation'))),
    validateOnBlur: false,
    onSubmit: (values) => {
      createAudit({ auditState: values, supplierId: supplierId! });
    },
  });

  const handleOnRowClick = (row: Audit) => {
    setSelectedAudit(audits?.find((item) => item.audit === row.audit) as Audit);
  };

  const getTableData = () =>
    audits
      ?.filter((item) => item.status !== 'ARCHIVED')
      .map((audit: Audit) => ({
        audit: audit.audit || '--',
        name: audit.name || '--',
        auditType: audit?.auditType?.title || '--',
        notes: audit.notes || '--',
        addedBy: audit.user?.fullName || '--',
        dateCompleted: audit?.endDate ? DateTime.fromISO(audit?.endDate).toLocaleString(DateTime.DATE_MED) : '--',
        dateAdded: DateTime.fromISO(audit?.modified || '').toLocaleString(DateTime.DATE_MED) || '--',
        auditDocuments: `${audit?.documents ? audit.documents.length : 0} files`,
        menu: (
          <QMenuButton buttonLabel="menu" variant="icon">
            <QMenuItem onClick={() => handleOnRowClick(audit)}>View documents</QMenuItem>
            <QMenuItem isDisabled={!isAuditEditable(supplier.status)} onClick={() => setDeleteAuditModalState(audit)}>
              {isAuditDeleteLoading ? <QSpinner /> : 'Delete'}
            </QMenuItem>
          </QMenuButton>
        ),
      })) || [];

  if (isAuditsLoading) {
    return (
      <QBox w="100%" textAlign="center" p={5}>
        <QSpinner />
      </QBox>
    );
  }

  const isAuditEditable = (status: any) => {
    // Adding audits are not allowed when supplier is in pending review status
    return status !== 'REVIEW_PENDING';
  };

  return (
    <>
      {!audits?.filter((item) => item.status !== 'ARCHIVED')?.length ? (
        <QEmptyState
          title="Completed audits will appear here"
          subtitle="Record the outcome of prior audits with completion date, related files and notes."
          primaryButtonProps={{
            label: 'Add an audit',
            isDisabled: !isAuditEditable(supplier.status),
            onClick: () => setShowAddAuditModal(true),
          }}
        />
      ) : (
        <QBox>
          <NextAudit
            key={supplier.audits?.length}
            audits={audits}
            policyLinks={policyLinks}
            supplierType={supplier?.supplierType as ConfigItem}
            riskLevel={supplier?.riskType as ConfigItem}
            auditTypes={auditTypeOptions as unknown as ConfigItem[]}
            supplier={supplier.supplier}
            isDetails={true}
          />
          <QBox textAlign="right" pb={3}>
            <QButton isDisabled={!isAuditEditable(supplier.status)} onClick={() => setShowAddAuditModal(true)}>
              Add Audit
            </QButton>
          </QBox>
          <deprecated.QDataTable columns={COLUMNS} data={getTableData()} />
          <QDrawer
            title={selectedAudit?.name as string}
            isOpen={!!selectedAudit}
            onClose={() => setSelectedAudit(null)}
          >
            {selectedAudit?.documents?.length ? (
              <QStack direction="column" spacing={4}>
                {selectedAudit.documents.map((document, index) => (
                  <ViewFileCard key={index} file={document.file} />
                ))}
              </QStack>
            ) : (
              <QAlert status="info" description="There are no documents available" />
            )}
          </QDrawer>
        </QBox>
      )}
      <QModal isOpen={!!deleteAuditModalState} onClose={() => setDeleteAuditModalState(undefined)}>
        <QModalHeader>
          <QText>Delete Audit</QText>
          <QCloseButton onClick={() => setDeleteAuditModalState(undefined)} />
        </QModalHeader>
        <QModalBody>Are you sure you would like to delete this audit?</QModalBody>
        <QModalActions>
          <QButton variant="outline" onClick={() => setDeleteAuditModalState(undefined)}>
            Cancel
          </QButton>
          <QButton
            onClick={() =>
              !!deleteAuditModalState && deleteAudit({ auditState: deleteAuditModalState, supplierId: supplierId! })
            }
            isLoading={isAuditDeleteLoading}
            data-cy="modal-delete-audit-button"
          >
            Delete audit
          </QButton>
        </QModalActions>
      </QModal>
      <QModal isOpen={showAddAuditModal} onClose={() => setShowAddAuditModal(false)} size="2xl">
        <QModalHeader>
          <QText>Add audit</QText>
          <QCloseButton
            onClick={() => {
              formik.resetForm();
              setShowAddAuditModal(false);
            }}
          />
        </QModalHeader>
        <QModalBody>
          <CreateAuditForm formik={formik} setAddAuditSubmitButtonDisabled={setAddAuditSubmitButtonDisabled} />
        </QModalBody>
        <QModalActions>
          <QButton
            variant="outline"
            onClick={() => {
              formik.resetForm();
              setShowAddAuditModal(false);
            }}
          >
            Cancel
          </QButton>
          <QButton
            onClick={() => formik.handleSubmit()}
            isDisabled={addAuditSubmitButtonDisabled}
            isLoading={isAuditLoading}
            data-cy="modal-add-audit-button"
          >
            Add audit
          </QButton>
        </QModalActions>
      </QModal>
    </>
  );
};

export default SupplierAudits;
